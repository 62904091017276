import logo from "./logo.svg";
import "./App.scss";

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Site is under construction/maintenance. Come back soon&#129299;</p>
      </header>
    </div>
  );
};

export default App;
